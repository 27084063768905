/* Centering the preview container */
.article-preview {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	text-align: center;
	background: #3b3e40;
	
  }
  
  /* Ensuring the image fits and is centered */
  .article-preview img {
	max-width: 100%; /* Ensure the image does not exceed its container's width */
	max-height: 400px; /* Set maximum height to twice the previous height */
	width: auto; /* Maintain aspect ratio */
	height: auto; /* Maintain aspect ratio */
	display: block;
	margin: 0 auto;
	
  }
  
  /* Adjusting the heading style */
  .article-preview h3 {
	margin-top: 1rem;
	color: #00FFFF; /* Adjust color as needed */
  }
  
  /* Styling for the link */
  .article-link {
	text-decoration: none;
	color: inherit; /* Inherit color from the parent element */
	
  }
  
  .article-link img {
	display: block; /* Ensure the image behaves like a block element */
	
  }
  
  .article-link h3 {
	margin-top: 0.5rem; /* Adjust margin as needed */
  }
  