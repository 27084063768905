.support {
	font-family:  Mono;
	padding: 2rem;
	max-width: 800px; /* Adjust as needed */
	margin: 0 auto;
	text-align: center;
  }
  
  .support h2 {
	font-size: 2.5rem;
	color: #00FFFF; /* Electric Blue for the header */
	margin-bottom: 1.5rem;
  }
  
  .support p {
	font-size: 1rem;
	color: #FFF;
	line-height: 1.6;
	margin-bottom: 1.5rem;
	text-align: left; /* Left align the paragraph text */
  }
  
  .coffee-link {
	display: inline-block;
	padding: 0.5rem 1rem;
	background-color: #FFDD00; /* Buy Me a Coffee brand color */
	color: #000;
	font-size: 1.2rem;
	border-radius: 5px;
	text-decoration: none;
	transition: background-color 0.3s;
  }
  
  .coffee-link:hover {
	background-color: #FFCC00; /* Slightly darker shade for hover effect */
  }
  