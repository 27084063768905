/* src/components/CapHitPredictor.css */

.clusters {
	font-family: Roboto Mono;
	padding: 1rem;
	text-align: center;
  }
  
  .clusters iframe {
	width: 100%;
	height: 800px; /* Adjust height as needed */
	border: none;
  }
  
  .clusters h2 {
	color: #00FFFF; /* Electric Blue color */
	font-size: 2rem; /* Adjust font size as needed */
	margin-bottom: 1rem;
  }
  
  .credits {
	margin-top: 1rem;
	font-size: 1rem;
	color: #FFF; /* Electric Blue color */
  }

  .clusters a {
	color: #00FFFF; /* Light Cyan for links */
	text-decoration: underline; /* Underlined text for links */
  }
  
  .clusters a:hover {
	color: #FFFF00; /* Yellow for links on hover */
  }
  
  .clusters a:visited {
	color: #00FFFF; /* Magenta for visited links */
  }