/* Container for the home page */
.home {
	font-family: Roboto Mono;
	padding: 1rem;
	text-align: center; /* Center-aligns all text within this container */
  }
  
  /* Styling for the Featured Articles header */
  .home h2 {
	font-family: Roboto Mono;
	font-size: 2rem; /* Adjust font size as needed */
	margin-bottom: 2rem; /* Space below the header */
	color: #00FFFF;
  }
  
