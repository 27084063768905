/* Main container */
.profile-container {
	background-color: #3b3e40;
	padding: 20px;
	border-radius: 8px;
  }
  
  /* Flex container to split into two columns */
  .profile-content {
	display: flex;
	justify-content: space-between; /* Create space between the two columns */
	align-items: flex-start; /* Align both columns to the top */
  }
  
  /* Left column for line chart and callout cards */
  .left-column {
	flex: 1;
	padding-right: 20px; /* Add space between the two columns */
  }
  
  /* Right column for bar chart */
  .right-column {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start; /* Align chart title and bar chart to the start */
  }
  
  /* Chart container */
  .chart-container {
	max-width: 575px;
  }
  
  .callout-cards {
	display: grid;
	grid-template-columns: repeat(3, 1fr); /* Three columns */
	grid-template-rows: repeat(2, auto); /* Two rows */
	gap: 20px; /* Space between cards */
	margin-top: 20px; /* Space above cards */
  }

  .card {
	background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent white */
	border: 1px solid rgba(255, 255, 255, 0.3);
	border-radius: 5px;
	padding: 10px;
	color: white; /* Default text color */
  }
  
  .card h2 {
	margin: 0;
	font-size: 18px;
  }
  
  .card p {
	font-size: 24px;
	margin: 0;
  }
  
  /* Bar chart container */
  .bar-chart-container {
	width: 100%;
	max-width: 550px; /* Adjust as needed */
	height: 500px;
	margin-top: 20px;
  }
  
  .bar-chart-title {
	background-color: #e4e5e7;	
	padding: 5px;
	width: 90%;
	border-radius: 8px;
	text-align: center;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	display: flex;
    flex-direction: column;
    justify-content: center;

  }

  .chart-title{
	background-color: #e4e5e7;
	padding: 5px;
	border-radius: 8px;
	text-align: center;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	display: flex;
    flex-direction: column;
    justify-content: center;

  }
  