/* Navbar container styling */
.navbar {
	font-family: 'Roboto Mono';
	background-color: #5a5d60; /* Light Cyan */
	color: #484b4e; /* Dark Color for Text */
	padding: 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 7%;
  }
  
  /* Logo and heading container */
  .navbar-brand {
	display: flex;
	align-items: center; /* Align logo and heading vertically */
  }
  
  /* Styling for the logo */
  .logo {
	height: 80px; /* Adjust the height as needed */
	margin-right: 10px; /* Space between logo and heading */
  }
  
  /* Styling for the heading */
  .navbar-title {
	margin: 0; /* Remove default margin */
	color: #00FFFF; /* Dark Color for Heading */
	margin-right: 10px; /* Add space between heading and icons */
  }
  
  /* Links styling */
  .navbar .links a {
	color: #00FFFF; /* Dark Color for Links */
	text-decoration: none;
	margin: 0 1rem;
  }
  
  .navbar .links a:hover {
	text-decoration: underline;
  }
  
  /* Dropdown styling */
  .dropdown {
	position: relative;
	display: inline-block;
	text-decoration: none;
  }
  
  .dropbtn {
	background-color: #5a5d60;
	color: #00FFFF;
	border: none;
	cursor: pointer;
	padding: 0.5rem 1rem;
	font-size: 1rem;
	display: flex;
	align-items: center;
	font-size: 15px;
	font-family: 'Roboto Mono';


  }
  
  .dropbtn .fas {
	margin-left: 0.5rem;
  }
  
  .dropdown-content {
	display: none;
	position: absolute;
	background-color: #484b4e; /* Same as navbar */
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
  }
  
  .dropdown-content a {
	color: white; /* Same as navbar text color */
	padding: 12px 16px;
	text-decoration: none;
	display: block;
  }
  
  .dropdown-content a:hover {
	background-color: #00CCCC; /* Slightly darker for hover effect */
  }
  
  .dropdown:hover .dropdown-content {
	display: block;
  }
  
  /* Styling for the icons */
  .footer-icon {
	color: #7df9ff; /* Electric blue */
	margin-left: 10px; /* Add space between the heading and the icons */
	font-size: 1.5rem; /* Adjust the size of the icons */
	text-decoration: none;
  }
  
  .footer-icon:hover {
	color: #FFFF00; /* Yellow for icons on hover */
  }
  