.App {
	display: flex;
	flex-direction: column;
	height: 100vh;
  }
  
  .navbar {
	flex: 0 1 auto;
  }
  
  .content {
	flex: 1 1 auto;
	padding: 20px;
	overflow-y: auto;
  }
  
  .shiny-app {
	padding: 20px;
  }

  .hidden-text{
	color: #484e48;
  }