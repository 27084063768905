/* src/components/Articles.css */
.articles {
	font-family: Roboto Mono;
	padding: 1rem;
	background-color: #3b3e40 ;
	align-items: center;
  }
  
  .articles h2 {
	font-size: 2rem;
	margin-bottom: 1rem;
	color: #00FFFF;
  }
  
  .articles-list {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
  }
  
  .article-item {
	background-color:  #5a5d60;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	width: calc(25% - 1rem); /* Three items per row with gap */
	transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .article-link {
	display: flex;
	flex-direction: column;
	text-decoration: none;
	color: #00FFFF;
  }
  
  .article-image {
	width: 100%;
	height: auto;
	object-fit: cover;
  }
  
  .article-title {
	padding: 1rem;
	font-size: 1.2rem;
	background-color: #82888d;
	text-align: center;
  }
  
  /* Hover effect for article-item */
  .article-item:hover {
	transform: scale(1.05); /* Slightly expand the item */
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhance the shadow */
  }