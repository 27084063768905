/* src/components/ModelNotes.css */

.model-notes {
	padding: 1rem;
	max-width: 800px; /* Adjust as needed */
	margin: 0 auto;
  }
  
  .model-notes h1 {
	font-size: 2.5rem;
	color: #00FFFF; /* Electric Blue for the header */
	margin-bottom: 1.5rem;
  }
  
  .model-notes section {
	margin-bottom: 2rem;
  }
  
  .model-notes h2 {
	font-size: 1.8rem;
	color: #00FFFF; /* Dark color for subheadings */
	margin-bottom: 1rem;
  }
  
  .model-notes p, .model-notes ul {
	font-size: 1rem;
	color: #FFF; /* Dark color for text */
	line-height: 1.6;
  }
  
  .model-notes ul {
	list-style-type: disc;
	margin-left: 1.5rem;
  }
  
  .model-notes li {
	margin-bottom: 0.5rem;
  }
  
  .model-notes a {
	color: #00FFFF; /* Electric Blue for links */
	text-decoration: none;
  }
  
  .model-notes a:hover {
	text-decoration: underline;
  }

  .scatter-image {
	max-width: 100%; /* Ensures image does not overflow container */
	height: auto; /* Maintains aspect ratio */
	display: block; /* Ensures image is a block element */
	margin: 1rem 0; /* Adds vertical spacing */
  }