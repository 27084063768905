/* Centering and styling the h1 and button container */
.header-container {
    display: flex;
    justify-content: space-between; /* Align button to the left and h1 to the center */
    align-items: center; /* Vertically center the items */
	padding-bottom: 15px;
}

/* Center the h1 */
.header-container h1 {
	align-items: center;
	text-align: center;
	padding-left: 18%;
    color: #00FFFF;
    flex-grow: 1; /* Allow h1 to grow and fill the remaining space */
    margin: 0; /* Remove default margin */
}

/* Styling the button */
.header-container button {
    background-color: #e4e5e7; /* Electric blue background */
    color: black; /* White text */
    border: none;
    padding: 10px 20px; /* Add padding for a nicer appearance */
    border-radius: 5px; /* Rounded corners */
    font-size: 1rem; /* Adjust font size */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
	width: 11%;
}

/* Button hover effect */
.header-container button:hover {
    background-color: #00ffff; /* Darker blue on hover */
    transform: scale(1.05); /* Slightly enlarge button on hover */
}

/* Grid container styles */
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    padding: 2rem;
    background-color: #3b3e40;
}

/* Grid item styles */
.grid-item {
    background-color: #e4e5e7;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    min-height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Grid item text */
.grid-item h3 {
    margin: 0;
    font-size: 1.2rem;
    color: #00FFFF;
}

.grid-item p {
    margin: 5px 0;
    font-size: 0.9rem;
}

/* Hover effect for grid items */
.grid-item:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.filters {
	display: flex;
	justify-content: center;
	gap: 20px; /* Space between the labels */
	margin: 20px 0;
  }
  
  .filters label {
	font-size: 16px;
	color: #00ffff;
  }
  
  .filters select {
	margin-left: 10px;
	padding: 5px;
	border-radius: 5px;
	border: 1px solid #ccc;
	background-color: #f9f9f9;
	font-size: 16px;
	cursor: pointer;
  }
  
  .filters select:focus {
	outline: none;
	border-color: #00bfff; /* Electric blue color for focus state */
	box-shadow: 0 0 5px rgba(0, 191, 255, 0.5);
  }
  
  .filters select option {
	padding: 10px;
  }

  .ratings-explanation{
	max-width: 100%;
	text-align: center;
	justify-content: center;
	position: relative;
	color: #00ffff;
  }
  