/* src/components/Article.css */

body {
	background-color: #484b4e; /* Background color for the entire page */
  }
  
  .privacy-policy {
	width: 75%;
	margin: 2rem auto; /* Centers the article content */
	padding: 2rem;
	background-color: #5a5d60; /* Slightly lighter grey background for the article */
	color: white; /* White text color */
	border-radius: 8px; /* Optional: Adds rounded corners */
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
  }
  
  .privacy-policy h1, .privacy-policy h3 {
	color: #00FFFF; /* Light Cyan for headings */
  }

  .privacy-policy h2, .privacy-policy h4 {
	color: white;
  }
  
  .privacy-policy img {
	max-width: 100%;
  }
  
  .privacy-policy a {
	color: #00FFFF; /* Light Cyan for links */
	text-decoration: underline; /* Underlined text for links */
  }
  
  .privacy-policy a:hover {
	color: #FFFF00; /* Yellow for links on hover */
  }
  
  .privacy-policy a:visited {
	color: #00FFFF; /* Magenta for visited links */
  }