/* src/components/Feedback.css */

.feedback {
	font-family: Roboto Mono;
	padding: 2rem;
	max-width: 800px; /* Adjust as needed */
	margin: 0 auto;
  }
  
  .feedback h1 {
	font-size: 2.5rem;
	color: #00FFFF; /* Electric Blue for the header */
	margin-bottom: 1.5rem;
  }
  
  .feedback section {
	margin-bottom: 2rem;
  }
  
  .feedback h2 {
	font-size: 1.8rem;
	color: #00FFFF; /* Dark color for subheadings */
	margin-bottom: 1rem;
  }
  
  .feedback p {
	font-size: 1rem;
	color: #FFF; /* Dark color for text */
	line-height: 1.6;
  }
  
  .feedback a {
	color: #00FFFF; /* Light Cyan for links */
	text-decoration: underline; /* Underlined text for links */
  }
  
  .feedback a:hover {
	color: #FFFF00; /* Yellow for links on hover */
  }
  
  .feedback a:visited {
	color: #00FFFF; /* Magenta for visited links */
  }