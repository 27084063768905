/* src/components/Footer.css */

.footer {
	width: 100%;
	text-align: center;
	padding: 1rem 0;
	bottom: 0;
	background-color: #484b4e; /* Adjust to your background color */
  }
  
  .footer-line {
	width: 85%;
	margin: 0 auto;
	border: 0;
	height: 1px;
	background-color: #00FFFF; /* Adjust to your line color */
  }
  
  .footer-link {
	color: #00FFFF; /* Light Cyan for the link */
	text-decoration: none;
	margin: 0 10px; /* Add space between the links */
	margin-top: 0.5rem;
  }
  
  .footer-link:hover {
	color: #FFFF00; /* Yellow for link on hover */
  }
  